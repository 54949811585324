import { VFC, useEffect, useState } from "react";
import {
  Heading,Text,Center,Box,
} from "@chakra-ui/react";
import { surveyObj } from '../../types/csat';
import { useLoginUser } from "../../hooks/providers/useLoginUserProvider";
import { MultiText } from '../../components/atoms/text/MyText';

type Props = {
  // eslint-disable-next-line
  errObj: any;
  opeErrMes: string;
  surveyObj: surveyObj|undefined;
};

export const PageError: VFC<Props> = (props) => {
  const { errObj, opeErrMes="", surveyObj } = props;
  const [ errMes,setErrMes ] = useState(opeErrMes);  // 画面側のオリジナルチェックエラー
  const [ errStatus,setErrStatus ] = useState("");
  const [ invalidVersionFlag, setInvalidVersionFlag ] = useState(false);
  const { loginUser } = useLoginUser();
  
  useEffect(() => {
    // errObjがnullの場合は、opeErrMesが設定されているパタンなので、errObj判定はスキップ
    loginUser.envType === 'DEV' && console.log("ErrorDialog",errObj);
    if( !errObj ){
      return;
    }
    
    // errObjがある場合は、エラー判定
    if( errObj.response ){
      setErrStatus(errObj.response.status);
      
      if( errObj.response.status === 503 ){
        // 503返却時はメンテナンス対応
        loginUser.envType === 'DEV' && console.log("メンテナンス中");
        window.location.replace("https://" + window.location.host + "/maintenance.html");
        
      } else if( errObj.response.data !== undefined && errObj.response.data !== null ){
        if(typeof(errObj.response.data) === 'object') {
          loginUser.envType === 'DEV' && console.log("opeErrMes1",errObj.response.data);
          if( errObj.response.data.message !== undefined && errObj.response.data.message !== null ){
            setErrMes(errObj.response.data.message);
          }else if( errObj.response.data.error_detail !== undefined && errObj.response.data.error_detail !== null ){
            setErrMes(errObj.response.data.error_detail);
          }else{
            setErrMes('システムエラー：不正なオブジェクト');
          }
            
        }else if(typeof(errObj.response.data) === 'string') {
          loginUser.envType === 'DEV' && console.log("opeErrMes2",errObj.response.data);
          setErrMes(errObj.response.data);
            
        }else{
          setErrMes('予期しないエラー');
        }
        
      }else{
        // BodyがないAPI GWエラー
        setErrMes("システムエラー：不正なヘッダ値");
      }
      
    }else{
      // BodyがないAPI GWエラー
      setErrMes("システムエラー：ネットワークエラー");
    }
    // eslint-disable-next-line
  }, [errObj]);
  
  useEffect(() => {
    if( errMes === 'api_version not match' ){
      setInvalidVersionFlag(true);
    }
  }, [errMes,errStatus]);
  
  return (
    <Center> 
      <Box
        maxW="720px"
        bg="white"
        shadow="md"
        padding="10px"
        color="black"
      >
        <Heading size="md" margin="10px">
          <Text>{surveyObj !== undefined ? surveyObj.title : 'アンケートエラー'}</Text>
        </Heading>
        {invalidVersionFlag ? (
          <>
          <Text color="tomato">本システムをアップデートいたしました。</Text>
          <Text>現在、古い画面がキャッシュされておりますので、お手数ですが、ブラウザのリロードボタンから、ページ全体をリロードお願いいたします。</Text>
          </>
        
        ) : opeErrMes || errMes === 'User is not authorized to access this resource with an explicit deny' ? (
          <Box padding="10px" margin="10px" bgColor="lightyellow" border="1px" borderRadius="5px" borderColor="gray">
            <Text>ご協力大変ありがとうございます。</Text>
            <Text>大変申し訳ございませんが、現在ご指定のアンケートは、終了しているか、有効ではございません。</Text>
          </Box>
      
        ) : (
          <Box padding="10px" margin="10px" bgColor="lightyellow" border="1px" borderRadius="5px" borderColor="gray">
            <MultiText str={surveyObj !== undefined ? surveyObj.endMessage : '大変申し訳ございません。\nただいまアンケートシステムはメンテナンス中です。'} />
          </Box>
        )}
      </Box>
    </Center>
    
  );
};
