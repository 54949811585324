export const getAwsConfig = () => {

  const baseApiInfo = process.env.REACT_APP_API_INFO;

  if (baseApiInfo == null) {
    throw Error('Environment variable not defined')
  }
  
  // console.log('baseApiInfo = ' + baseApiInfo);
  // console.log('baseAuthInfo = ' + baseAuthInfo);
  
  const apiInfo = baseApiInfo.split(',');

  // API
  let backendApiUrl = apiInfo[0];
  if (apiInfo[0] === '' && apiInfo.length === 3) {
    // カスタムドメイン設定あり
    backendApiUrl = 'https://' + apiInfo[1];
  }
  
  return ({
    API: {
      endpoints: [
        { name: 'BackendApi', endpoint: backendApiUrl }
      ]
    }
  });
};
