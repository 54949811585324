import React from 'react';
import './App.css';
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme/theme";
import { Router } from "./router/Router";
import { Amplify } from 'aws-amplify';
import { getAwsConfig } from './aws-config'
import '@aws-amplify/ui-react/styles.css';

function App() {
  Amplify.configure(getAwsConfig());
  
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
