import { memo, VFC, useState, useEffect, useCallback, useRef, ChangeEvent } from 'react';
import {
  Center, Heading, Box, Text, Flex, Spacer,
  Radio, RadioGroup, Checkbox, CheckboxGroup, Textarea, Input,
  FormControl, UnorderedList, ListItem, Divider, Spinner,
} from '@chakra-ui/react';
import { useLoginUser } from "../../hooks/providers/useLoginUserProvider";
import { questionObj, itemObj, resultObj, postObj } from '../../types/csat';
import { useCSat } from '../../hooks/useCSat';
import { PrimaryButton } from '../../components/atoms/button/PrimaryButton';
import { MultiText } from '../../components/atoms/text/MyText';
import { StarRating } from '../../components/atoms/radio/StarRating';
import { PageError } from './PageError';

export const CSatInput: VFC  = memo(() => {
  const [ page, setPage ] = useState('input');
  const { loginUser } = useLoginUser();
  const [ ansList, setAnsList ] = useState<resultObj|null>(null);
  const [ requiredMes, setRequiredMes ] = useState<Array<string>>([]);

  const { getSurvey, surveyObj, postResult, postResultObj, executing, errObj } = useCSat();
  const [ surveyId, setSurveyId ] = useState('');
  
  const [ surveyVersion, setSurveyVersion ] = useState('');
  const [ caseId, setCaseId ] = useState('');
  const [ option, setOption ] = useState('');
  const [ star, setStar ] = useState('');
  
  const [ mode, setMode ] = useState('');
  const [ opeErrMes, setOpeErrMes ] = useState('');
  const [ execFlag, setExecFlag ] = useState(false);
  const [ doneFlag, setDoneFlag ] = useState(false);
  const [ ansKey, setAnsKey ] = useState('');
  const initFlag = useRef('uninit')
  
  // 調査情報の取得１
  useEffect(() => {
    // console.log('ここ',sessionStorage.getItem('id'));
    setSurveyId(sessionStorage.getItem('id') ?? '');
    setCaseId(sessionStorage.getItem('caseId') ?? '');
    setOption(sessionStorage.getItem('option') ?? '0');
    setStar(sessionStorage.getItem('star') ?? '');
    setExecFlag(true);
  }, []);
  
  useEffect(() => {
    if( execFlag ){
      setExecFlag(false);
      
      // セッションクリア
      sessionStorage.removeItem('id');
      sessionStorage.removeItem('caseId');
      sessionStorage.removeItem('option');
      sessionStorage.removeItem('star');
    
      // パラメータチェック
      const reg = new RegExp(/^[a-zA-Z0-9\-_@.]{0,100}$/);
      loginUser.envType === 'DEV' && console.log(surveyId,reg.test(surveyId),caseId,reg.test(caseId));
      if( surveyId === '' || !reg.test(surveyId) ||
          caseId === '' || !reg.test(caseId)  ||
          (option !== '0' && option !== '1') ){
        setOpeErrMes('param_error');
      }else{
        getSurvey(surveyId);
      }
    }
  }, [getSurvey,execFlag,caseId,surveyId,option,loginUser.envType]);
  
  // 登録実行
  const registSurvey = useCallback((nextPage:string) => {
    
    // console.log(ansList,initFlag.current,ansKey);
    
    // 未回答 || 登録実施済なのに連続して実施されサーバからまだKey返却がない場合はスキップ
    if( ansList === null || (initFlag.current === 'done' && !ansKey) ) return;
    
    // 正常回答時（status=answered）
    if( nextPage ){
      // ページ先頭に移動
      setPage(nextPage);
      let ansListTmp:resultObj = ansList;
      ansListTmp['status'] = 'answered';
    }
    
    // 初期化実行済みにする
    initFlag.current = 'done';
    
    let postObj:postObj = {
      mode: ansKey === '' ? mode : 'update',
      id: surveyId,
      version: surveyVersion,
      caseId: caseId,
      data: ansList,
      key: ansKey,
    }
    postResult(postObj);
    
  }, [postResult,ansList,mode,caseId,surveyId,surveyVersion,ansKey,initFlag]);
  
  // 新規登録の結果、キーを保存｜解凍ボタンの結果は待つ
  useEffect(() => {
    // 更新キーが未設定の場合、設定
    if( typeof postResultObj !== 'undefined' && postResultObj.result === 'succeed' && !ansKey ){
      setAnsKey(postResultObj.key);
    }
    
    // 解凍ボタン選択時は確実に待つ
    if( !executing && typeof postResultObj !== 'undefined' && postResultObj.result === 'succeed' && page === 'ending' ){
      setPage('end');
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [executing,postResultObj,ansKey,page]);
  
  // データ格納域初期化
  useEffect(() => {
    if( surveyObj ){
      setSurveyVersion(surveyObj.version);
      setMode(typeof surveyObj.mode === 'undefined' ? 'add_single' : surveyObj.mode);
      
      // idとファイル内のidが同じであること
      if( surveyId !== surveyObj.id ){
        setOpeErrMes('設定ファイルのIDと引数のIDが一致しない');
        loginUser.envType === 'DEV' && console.log('設定ファイルのIDと引数のIDが一致しない');
        return;
      }
      
      // 有効期間チェック
      const current = (new Date()).getTime();
      // 開始・終了日時
      const start = (new Date(surveyObj.startDT+':00.000')).getTime();
      const end = (new Date(surveyObj.endDT+':59.999')).getTime();
      
      if( isNaN(start) || isNaN(end) ){
        setOpeErrMes('日付の指定に誤りがある');
        loginUser.envType === 'DEV' && console.log('日付の指定に誤りがある');
        return;
      }
      loginUser.envType === 'DEV' && console.log(current,surveyObj.startDT,start,surveyObj.endDT,end);
      if( current < start || end < current ){
        setOpeErrMes('有効期間ではない');
        loginUser.envType === 'DEV' && console.log('有効期間ではない');
        return;
      }
      
      let ansListTmp:{ [key: string]: string|Array<string> } = {};
      
      surveyObj.questions.map((qObj:questionObj) => {
        qObj.inputType === 'checkbox' ?  ansListTmp[qObj.qId] = [] : ansListTmp[qObj.qId] = '';
        
        qObj.items.map((item:itemObj)=> {
          if( typeof item.freeText !== 'undefined' ){
            ansListTmp[qObj.qId+'_freetext'] = '';
          }
          return('');
        })
        
        return('');
      })
      
      // アンケートが開かれた記録準備（status=opened）
      if( initFlag.current === 'uninit' ){
        // if( surveyObj.nextUrl ) ansListTmp['next'] = '0';
        initFlag.current = 'doing-step1';
        ansListTmp['status'] = 'opened';
      
        // 初期質問結果の格納
        if( typeof surveyObj.firstQuestion === 'object' ) ansListTmp[surveyObj.firstQuestion.qId] = star;
        
        setAnsList({...ansListTmp});
        loginUser.envType === 'DEV' && console.log(ansListTmp);
      }
    }
  }, [surveyObj,loginUser,surveyId,initFlag,setAnsList,star]);
  
  // アンケートが開かれたことを記録指示（registSurvey呼び出し）
  useEffect(() => {
    if( ansList && initFlag.current === 'doing-step1' ){
      initFlag.current = 'doing-step2';
      registSurvey('');
      loginUser.envType === 'DEV' && console.log('アンケートが開かれた！');
    }
  }, [ansList,initFlag,registSurvey,loginUser]);
  
  // 必須チェック
  useEffect(() => {
    if( surveyObj && ansList !== null ){
      let mesTmp:string[] = [];
      surveyObj.questions.map((qObj:questionObj) => {
        
        // 必須、かつ値入力がない
        if( qObj.requiredFlag && ansList[qObj.qId] === '' ){
          mesTmp.push(qObj.qName);
        }
        return('');
        
      })
      setRequiredMes([...mesTmp]);
    }
  }, [surveyObj,ansList]);

  // ラジオボタン用
  const radioFunc = useCallback((selChoice:string, qId: string) => {
    if( ansList === null ) return;
    
    // console.log(selChoice, qId);
    let ansListTmp:resultObj = ansList;
    ansListTmp[qId] = selChoice;
    setAnsList({...ansListTmp});
    loginUser.envType === 'DEV' && console.log(ansListTmp);
        
    // radioの必須時はチェックのタイミングで保存（結果は基本待たない）
    surveyObj && surveyObj.questions.map((qObj:questionObj) => {
      if( qObj.qId === qId && qObj.saveFlag ){
        registSurvey('');
        return('');
      }
      return('');
    })
  }, [ansList,loginUser,registSurvey,surveyObj]);
  
  // チェックボックス用
  const checkFunc = useCallback((selChoice:any, qId: string) => {
    if( ansList === null ) return;
    
    // console.log(selChoice, qId);
    let ansListTmp:resultObj = ansList;
    ansListTmp[qId] = selChoice;
    setAnsList({...ansListTmp});
    loginUser.envType === 'DEV' && console.log(ansListTmp);
  }, [ansList,loginUser]);
  
  // 入力域用
  const inputAreaFunc = useCallback((e:ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, qId: string) => {
    if( ansList === null ) return;
    
    let ansListTmp:resultObj = ansList;
    
    // 字数制限
    if( e.target.value.length ){
      // ansListTmp[qId] = (e.target.value).substr(0,size);
      ansListTmp[qId] = e.target.value;
    }else{
      ansListTmp[qId] = '';
    }
    setAnsList({...ansListTmp});
    loginUser.envType === 'DEV' && console.log(ansListTmp);

  }, [ansList,loginUser]);
    
  // 入力長チェック（上記の入力時に実施しようとしたがうまく行かないので、こちらに切り出し）
  useEffect(() => {
    if( surveyObj && ansList !== null ){
      let ansListTmp:resultObj = ansList;
      let updFlag = false;
      // console.log('ここ');
      surveyObj.questions.map((qObj:questionObj) => {
        
        if( qObj.inputType === 'textarea' && qObj.size && ansListTmp[qObj.qId].length > qObj.size ){
          ansListTmp[qObj.qId] = ansListTmp[qObj.qId].substr(0,qObj.size);
          updFlag = true;
        }else{
          qObj.items.map((item:itemObj)=> {
            if( typeof item.freeText !== 'undefined' && qObj.size && ansListTmp[qObj.qId+'_freetext'].length > qObj.size ){
              ansListTmp[qObj.qId+'_freetext'] = ansListTmp[qObj.qId+'_freetext'].substr(0,qObj.size);
              updFlag = true;
            }
            return('');
          })
        }
        return('');
        
      })
      if( updFlag ) setAnsList({...ansListTmp});
    }
  }, [surveyObj,ansList]);
  
  // 次のURLへ
  const goNext = useCallback(async() => {
    if( typeof surveyObj === 'undefined' || !surveyObj.nextUrl ||
        !ansKey || ansList === null ) return;
    
    let ansListTmp:resultObj = ansList;
    // ansListTmp['next'] = '1';
    ansListTmp['status'] = 'moved';
      
    let postObj:postObj = {
      mode: 'update',
      id: surveyId,
      version: surveyVersion,
      caseId: caseId,
      data: ansListTmp,
      key: ansKey,
    }
    postResult(postObj);
    
    window.location.href = surveyObj.nextUrl;
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setDoneFlag(true);
    
  }, [postResult,ansList,surveyObj,caseId,surveyId,surveyVersion,ansKey]);
  
  // ラジオやチェックボックス時の設定値作成
  function replaceId(val:any,qId:string){
    if( typeof val === 'string' ){
      return( val.replace(qId+'_','') );
    }else{
      return(val);
    }
  }
  
  // 自由入力域のクリア判断  
  function clearCheck(val1:any,val2:any,qId:string){
    if( typeof val1 === 'string' && typeof val2 === 'string' && ansList !== null ){
      if( val1 !== val2 && ansList[qId+'_freetext'] ){
        let ansListTmp:resultObj = ansList;
        ansListTmp[qId+'_freetext'] = '';
        setAnsList({...ansListTmp});
        // console.log(val1,val2,qId,ansList);
      }
      return(ansList[qId+'_freetext']);
    }else{
      return('');
    }
  }
  
  return (
    <>
    { (errObj || opeErrMes) ? (
      <PageError errObj={errObj} opeErrMes={opeErrMes} surveyObj={surveyObj} />
        
    ) : surveyObj === undefined ? (
      <Center h="20vh">
        <Text>アンケートの準備をしておりますので、しばらくお待ちください。。</Text>
        <Spinner />
      </Center>
      
    ) : (page === "input" || page ==="ending") && ansList !== null ? (
      <Center> 
        <Box
          maxW="720px"
          bg="white"
          shadow="md"
          padding="10px"
          color="black"
        >
          <Heading size="md" margin="10px">
            <Text>{surveyObj.title}</Text>
          </Heading>
          
          <Box padding="10px" margin="10px" bgColor="lightyellow" border="1px" borderRadius="5px" borderColor="gray">
            <MultiText str={surveyObj.exp} />
          </Box>
          
          <Box padding="10px" margin="10px" border="1px" borderRadius="5px" borderColor="gray">
          <FormControl>
          {surveyObj.questions.map((qObj:questionObj) => {
            return(
              <Box key={qObj.qId} border="1px" borderColor="lavender" paddingBottom="15px">
              <Box border="1px" borderColor="lavender" paddingTop="1px" paddingBottom="1px" paddingLeft="10px" backgroundColor={ansList[qObj.qId] ? "lightcyan" : "seashell"}>
                <Flex>
                  <Box>
                    <MultiText str={qObj.qName} />
                  </Box>
                  <Spacer />
                  <Text color="red" fontSize="xs">{qObj.requiredFlag ? '[必須]　' : ''}</Text>
                </Flex>
              </Box>
              
              {qObj.inputType === 'star' ? (
                <Box marginLeft="5px" marginTop="5px">
                  <StarRating totalStars={qObj.items.length} size={5} radioFunc={radioFunc} initStar={ansList[qObj.qId]} qId={qObj.qId} />
                </Box>
              
              ) : qObj.inputType === 'radio' ? (
                <RadioGroup onChange={(selChoice)=>radioFunc(selChoice,qObj.qId)} value={replaceId(ansList[qObj.qId],qObj.qId)}>
                {qObj.items.map((itemObj:itemObj) => {
                  return(
                    <Box key={itemObj.itemId}  backgroundColor="white" paddingLeft="10px">
                      <Flex>
                      <Radio value={itemObj.itemId}>{itemObj.itemName}</Radio>
                      {typeof itemObj.freeText !== 'undefined' &&
                      typeof ansList[qObj.qId+'_freetext'] !== 'undefined' && (
                        <Box paddingLeft="10px" width="400px">
                          <Input
                            value={clearCheck(ansList[qObj.qId],itemObj.itemId,qObj.qId)}
                            onChange={(e)=>inputAreaFunc(e,qObj.qId+'_freetext')}
                            size="xs"
                            placeholder={itemObj.freeText}
                            disabled={ansList[qObj.qId] === (itemObj.itemId) ? false : true}
                          />
                        </Box>
                      )}
                      </Flex>
                    </Box>
                  )
                })}
                </RadioGroup>
                
              ) : qObj.inputType === 'checkbox' ? (
                <CheckboxGroup onChange={(selChoice)=>checkFunc(selChoice,qObj.qId)} value={replaceId(ansList[qObj.qId],qObj.qId)}>
                {qObj.items.map((itemObj:itemObj) => {
                  return(
                    <Box key={itemObj.itemId}  backgroundColor="white" paddingLeft="10px">
                      <Checkbox value={itemObj.itemId}>{itemObj.itemName}</Checkbox>
                    </Box>
                  )
                })}
                </CheckboxGroup>
                
              ) : qObj.inputType === 'textarea' && (
                <Box paddingLeft="10px" paddingRight="10px">
                  <Textarea
                    value={ansList[qObj.qId]}
                    onChange={(e)=>inputAreaFunc(e,qObj.qId)}
                    placeholder={qObj.qExp}
                    size='sm'
                  />
                </Box>
              )}
              </Box>
            )
          })
          }
          </FormControl>
          </Box>
          
          <Divider paddingTop='20px' />
          {requiredMes.length > 0 && (
            <Box paddingLeft="20px">
            　<Text color="tomato">次の質問は必須のため、お手数ですが、ご回答お願いいたします。</Text>
              <UnorderedList paddingLeft="10px">
              {requiredMes.map((mes:string,index:number) => {
                return(
                  <ListItem key={index}>
                    <MultiText str={mes} size="sm" />
                  </ListItem>
                )
              })}
              </UnorderedList>
            </Box>
          )}
          <Center paddingTop="20px">
            <PrimaryButton type='ans' onClick={()=>registSurvey('ending')} disabled={requiredMes.length > 0 ? true : false} isLoading={executing}>回答する</PrimaryButton>
          </Center>
        </Box>
      </Center>
      
    ) : page === 'end' ? (
      <Center> 
        <Box
          maxW="720px"
          bg="white"
          shadow="md"
          padding="10px"
          color="black"
        >
          <Heading size="md" margin="10px">
            <Text>{surveyObj.title}</Text>
          </Heading>
          
          <Box padding="10px" margin="10px" bgColor="lightyellow" border="1px" borderRadius="5px" borderColor="gray">
            <MultiText size="sm" str={surveyObj.endMessage} />
          </Box>
          
          {surveyObj.nextUrl && option === '1' && !doneFlag && (
            <Box padding="10px" margin="10px" bgColor="lightyellow" border="1px" borderRadius="5px" borderColor="gray">
              <MultiText size="sm" str={typeof surveyObj.nextMessage !== 'undefined' ? surveyObj.nextMessage : ''} />
          
              <Center paddingTop="20px">
                <PrimaryButton type='ok' onClick={goNext}>{surveyObj.nextUrlName ?? '次へ'}</PrimaryButton>
              </Center>
            </Box>
          )}
          
        </Box>
      </Center>
      
    ) : (
      <></>
    )}
    </>
  );
});
