import { useCallback, useState } from 'react';
import { API } from 'aws-amplify';
import { surveyObj, postObj, postResultObj } from '../types/csat';
import { useLoginUser } from "../hooks/providers/useLoginUserProvider";

import crypto from 'crypto-js';


export const useCSat = () => {
  const {loginUser} = useLoginUser();
  const [executing, setExecuting] = useState(false); // API実行中かどうかのフラグ
  const [errObj, setErrObj] = useState(null);
  const [surveyObj, setSurveyObj] = useState<surveyObj|undefined>(undefined);
  const [postResultObj, setPostResultObj] = useState<postResultObj|undefined>(undefined);

  // token 生成
  const generateToken = (id: string) => {
    
    // 環境変数から取得する
    const password = process.env.REACT_APP_CIPHER_KEY;
    if (password == null) {
      throw Error('Environment variable not defined');
    }
  
    // 現在日時を UNIX 時間で取得
    const date = new Date().getTime();
    
    // 暗号化対象は [id]#[date]
    const target = id + '#' + date;
    
    // AES-256-ECB で暗号化
    const key = crypto.enc.Utf8.parse(password);
    const options = {
      mode: crypto.mode.ECB,
      padding: crypto.pad.Pkcs7
    };
    
    const enc = crypto.AES.encrypt(target, key, options);
    
    return enc.toString();
  };
  
  // 調査定義取得
  const getSurvey = useCallback((id: string) => {
    setExecuting(true);
    const apiName = 'BackendApi';
    const path = '/survey/master';
    
    const option = {
      headers: {
        'X-Survey-Token': generateToken(id),
        'X-Api-Version': process.env.REACT_APP_API_VERSION
      },
      queryStringParameters: {
        id: id,
      },
    };
    loginUser.envType === 'DEV' && console.log('get_survey_param', id);
  
    (async() => {
      await API.get(apiName, path, option)
      .then((res) => {
        loginUser.envType === 'DEV' && console.log('get_survey_res', res);
        setSurveyObj(res);
      })
      .catch((err) => {
        setErrObj(err);
        loginUser.envType === 'DEV' && console.log('rget_survey_res', err);
      })
      .finally(() => {
        setExecuting(false);
      });
    })();
    
  }, [loginUser.envType]);

  // 結果登録実行
  const postResult = useCallback((postObj: postObj) => {
    setExecuting(true);

    const apiName = 'BackendApi';
    const path = '/survey/result';
    
    const option = {
      headers: {
        'X-Survey-Token': generateToken(postObj.id),
        'X-Api-Version': process.env.REACT_APP_API_VERSION
      },
      body:  postObj,
    };
    loginUser.envType === 'DEV' && console.log("postResult_option",option);
    
    API.post(apiName, path, option)
      .then(async(res) => {
        loginUser.envType === 'DEV' && console.log("postResult_res",res);
        setPostResultObj(res);
      })
      .catch((err) => {
        setErrObj(err);
      })
      .finally(() => {
        setExecuting(false);
      });
  
    // setResult('success');
  }, [loginUser.envType]);
  
  return {
    getSurvey,
    postResult,
    surveyObj,
    postResultObj,
    executing,
    errObj,
  };
};
