import { VFC, memo } from "react";
import { Text, As } from "@chakra-ui/react";

type Props = {
  str: string;
  color?: string;
  bgColor? : string;
  size?: string;
  asStr?: As;
};

// XSテキスト返却
export const MyText: VFC<Props> = memo((props) => {
  const { str, color="black", bgColor="", size="md", asStr } = props;

  return (
    <>
    {typeof asStr === 'undefined' ?
      <Text fontSize={size} color={color} bgColor={bgColor}>{str}</Text>
    :
      <Text fontSize={size} color={color} bgColor={bgColor} as={asStr}>{str}</Text>
    }
    </>
  );
});

// 改行毎でXSTextを作成
export const MultiText: VFC<Props> = memo((props) => {
  const { str, color="black", bgColor="", size="md", asStr } = props;

  return (
    <>
    {typeof asStr === 'undefined' ? (
      str.split('\n').map((item,index) => (
        <Text key={index} fontSize={size} color={color} bgColor={bgColor}>{item === '' ? '　' : item}</Text>
      ))
    ) : (
      str.split('\n').map((item,index) => (
        <Text key={index} fontSize={size} color={color} bgColor={bgColor} as={asStr}>{item === '' ? '　' : item}</Text>
      ))
    )}
    </>
  );
});
