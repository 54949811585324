import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { User } from "../../types/csat";

type LoginUserContextType = {
  loginUser: User;
  setLoginUser: Dispatch<SetStateAction<User>>;
};

export const LoginUserContext = createContext<LoginUserContextType>(
  {} as LoginUserContextType
);

export const initUserObj ={
  envType: process.env.REACT_APP_ENV_TYPE === undefined ? 'DEV' : process.env.REACT_APP_ENV_TYPE,
};

// ログインユーザー情報を保持するcontext
export const LoginUserProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [loginUser, setLoginUser] = useState<User>(initUserObj);

  return (
    <LoginUserContext.Provider value={{ loginUser, setLoginUser }}>
      {children}
    </LoginUserContext.Provider>
  );
};

export const useLoginUser = (): LoginUserContextType =>
  useContext(LoginUserContext);
