import { CSatInput } from '../components/pages/CSatInput';
import { Page404 } from '../components/pages/Page404';

export const routes = [
  {
    name: 'アンケート',
    path: '/',
    children: <CSatInput />,
  },
  {
    name: 'アンケート',
    path: '/index',
    children: <CSatInput />,
  },
  {
    name: "４０４",
    path: '/*',
    children: <Page404 />,
  },
];
