import { VFC, useState, useCallback } from 'react';
import { StarIcon } from '@chakra-ui/icons'

type Props = {
  totalStars: number;
  size: number;
  radioFunc: (selectedStars:string,qId:string)=>void;
  initStar: string;
  qId: string;
};

export const StarRating: VFC<Props> = (props) => {
  const { totalStars, size, radioFunc, initStar, qId } = props;
  const [ selectedStars, setSelectedStars ] = useState(initStar);
  
  // 星の設定
  const clickFunc = useCallback((star:string) => {
    setSelectedStars(star);
    radioFunc(star,qId);
  }, [radioFunc,qId]);
  
  return (
    <>
      {[...Array(totalStars)].map((n, i) => (
        <StarIcon
          key={i}
          color={Number(selectedStars) > i ? "gold" : "gray"}
          w={size}
          h={size}
          aria-label='Star'
          marginRight="1px"
          onClick={() => clickFunc(String(i + 1))}
          _hover={{ backgroundColor: "gray.100" }}
         />
      ))}
    </>

  );
}