import { memo, VFC } from 'react';
import {
  Center, Box, Text,
} from '@chakra-ui/react';

export const Page404: VFC = memo(() => {
  return(
    <Center> 
      <Box
        width="100%"
        minW='sm'
        bg="white"
        shadow="md"
        color="black"
        marginTop="20px"
        padding="40px"
      >
        <Text>ご協力大変ありがとうございます。</Text>
        <Text>大変申し訳ございませんが、現在ご指定のアンケートは、終了しているか、有効ではございません。</Text>
      </Box>
    </Center>
  );
});
