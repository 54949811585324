import React, {ReactNode} from "react";
import { Route, Routes } from "react-router-dom";

import { routes } from "./Routes";
import { Page404 } from "../components/pages/Page404";
import { HeaderLayout } from "../components/organisms/layout/HeaderLayout";
import { LoginUserProvider } from "../hooks/providers/useLoginUserProvider";

export type routeObj = {
  name: string;
  path: string;
  children: ReactNode;
};

export const Router = () => {
  return (
    <LoginUserProvider>
      <Routes>
        {routes.map((route:routeObj) => (
          <Route
            key={route.path}
            path={route.path}
            element={<HeaderLayout>{route.children}</HeaderLayout>}
          />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </LoginUserProvider>
  );
};