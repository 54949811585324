import { memo, ReactNode, VFC, useState, useEffect } from "react";
import {
  Grid,GridItem,Center,Text,Flex,
} from "@chakra-ui/react";
import { useLoginUser } from "../../../hooks/providers/useLoginUserProvider";

type Props = {
  children: ReactNode;
};

export const HeaderLayout: VFC<Props> = memo(props => {
  const { children }= props;
  const { loginUser } = useLoginUser();
  const [ toYear, setToYear ] = useState('');
  
  const [ headerColor ] = useState(
    loginUser.envType === "DEV" ? "gray.500" :
    loginUser.envType === "STG" ? "teal.500" : "blue.500"
  );
  
  // フッタ用今年の取得
  useEffect(() => {
    const toYearTmp = (new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))).getFullYear();
    if( toYear === '' && toYearTmp !== 2023 ){
      setToYear('-'+toYearTmp);
    }
    // eslint-disable-next-line
  }, []);
  
  return (
    <Center>
      <Grid
        templateAreas={`"header"
                        "main"
                        "footer"`}
        gridTemplateRows={'48px 1fr 30px'}
        gridTemplateColumns={'1fr'}
        h='200px'
        color='blackAlpha.700'
      >
        <GridItem bg='white' area={'header'}>
          <Flex
            as="nav"
            bg={headerColor}
            color="gray.50"
            align="center"
            justify="space-between"
            padding={{ base: 3 }}
          >
            <Text></Text>
          </Flex>
        </GridItem>
        <GridItem area={'main'}>
          {children}
        </GridItem>
        <GridItem bg='white.100' area={'footer'}>
          <Center>
            <Text margin="20px">{`© NEC Corporation 2023${toYear}`}</Text>
          </Center>
        </GridItem>
      </Grid>
    </Center>
  );
});
