import { ReactNode, VFC } from 'react';
import { Button } from '@chakra-ui/react';

type Props = {
  children: ReactNode;
  isFullWidth?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  size?: string;
  type?: string;
  onClick: any;
};

export const PrimaryButton: VFC<Props> = (props) => {
  const {
    children,
    isFullWidth = false,
    disabled = false,
    isLoading = false,
    size = 'md',
    type = '',
    onClick,
  } = props;

  return (
    <Button
      id={type}
      bg={type === 'ng' ? 'gray.200' : type === 'ok' ? 'red.500' : 'orange.400'}
      color={type === 'ng' ? 'black' : 'white'}
      width={isFullWidth ? "full" : ''}
      isDisabled={disabled || isLoading}
      isLoading={isLoading}
      _hover={{ opacity: 0.8 }}
      size={size}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
